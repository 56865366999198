import { default as gig_45workersGxb3ih0qkGMeta } from "/app/apps/homekiq.com/pages/benefits/gig-workers.vue?macro=true";
import { default as homeownersGIpWfC2BFZMeta } from "/app/apps/homekiq.com/pages/benefits/homeowners.vue?macro=true";
import { default as service_45providerskoX0wgLHUNMeta } from "/app/apps/homekiq.com/pages/benefits/service-providers.vue?macro=true";
import { default as indexz1Z4Y6Nen6Meta } from "/app/apps/homekiq.com/pages/index.vue?macro=true";
import { default as investorsGjDdnaatMGMeta } from "/app/apps/homekiq.com/pages/investors.vue?macro=true";
import { default as onboardcgs6759zWgMeta } from "/app/apps/homekiq.com/pages/onboard.vue?macro=true";
import { default as privacyAMp6P2O2RXMeta } from "/app/apps/homekiq.com/pages/privacy.vue?macro=true";
export default [
  {
    name: gig_45workersGxb3ih0qkGMeta?.name ?? "benefits-gig-workers",
    path: gig_45workersGxb3ih0qkGMeta?.path ?? "/benefits/gig-workers",
    meta: gig_45workersGxb3ih0qkGMeta || {},
    alias: gig_45workersGxb3ih0qkGMeta?.alias || [],
    redirect: gig_45workersGxb3ih0qkGMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/benefits/gig-workers.vue").then(m => m.default || m)
  },
  {
    name: homeownersGIpWfC2BFZMeta?.name ?? "benefits-homeowners",
    path: homeownersGIpWfC2BFZMeta?.path ?? "/benefits/homeowners",
    meta: homeownersGIpWfC2BFZMeta || {},
    alias: homeownersGIpWfC2BFZMeta?.alias || [],
    redirect: homeownersGIpWfC2BFZMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/benefits/homeowners.vue").then(m => m.default || m)
  },
  {
    name: service_45providerskoX0wgLHUNMeta?.name ?? "benefits-service-providers",
    path: service_45providerskoX0wgLHUNMeta?.path ?? "/benefits/service-providers",
    meta: service_45providerskoX0wgLHUNMeta || {},
    alias: service_45providerskoX0wgLHUNMeta?.alias || [],
    redirect: service_45providerskoX0wgLHUNMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/benefits/service-providers.vue").then(m => m.default || m)
  },
  {
    name: indexz1Z4Y6Nen6Meta?.name ?? "index",
    path: indexz1Z4Y6Nen6Meta?.path ?? "/",
    meta: indexz1Z4Y6Nen6Meta || {},
    alias: indexz1Z4Y6Nen6Meta?.alias || [],
    redirect: indexz1Z4Y6Nen6Meta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investorsGjDdnaatMGMeta?.name ?? "investors",
    path: investorsGjDdnaatMGMeta?.path ?? "/investors",
    meta: investorsGjDdnaatMGMeta || {},
    alias: investorsGjDdnaatMGMeta?.alias || [],
    redirect: investorsGjDdnaatMGMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/investors.vue").then(m => m.default || m)
  },
  {
    name: onboardcgs6759zWgMeta?.name ?? "onboard",
    path: onboardcgs6759zWgMeta?.path ?? "/onboard",
    meta: onboardcgs6759zWgMeta || {},
    alias: onboardcgs6759zWgMeta?.alias || [],
    redirect: onboardcgs6759zWgMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/onboard.vue").then(m => m.default || m)
  },
  {
    name: privacyAMp6P2O2RXMeta?.name ?? "privacy",
    path: privacyAMp6P2O2RXMeta?.path ?? "/privacy",
    meta: privacyAMp6P2O2RXMeta || {},
    alias: privacyAMp6P2O2RXMeta?.alias || [],
    redirect: privacyAMp6P2O2RXMeta?.redirect || undefined,
    component: () => import("/app/apps/homekiq.com/pages/privacy.vue").then(m => m.default || m)
  }
]